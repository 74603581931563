import React from "react";
import "./css/SectionAbout.css"
import {NotebookIllustration} from "./NotebookIllustration";

export class SectionAbout extends React.Component {
    render() {
        return <section id="about" className="section yellow dark-font-font">
            <div className="sub-section reverse-section">
                <div className="container-box">
                    <div className="section-heading about-heading">About Me</div>
                    <div className="section-text about-text">
                        I studied Mathematics at the Universities of Siegen and Düsseldorf and been working as a
                        Software Developer since 2020.
                    </div>
                    <div className="section-text about-text">
                        I love doing Maths, drawing Illustrations, coding Games and making Music.
                        Bringing all these things together in private coding projects is one of the biggest sources of
                        joy for me (besides my people and dogs at home).
                    </div>
                </div>
                <NotebookIllustration/>
            </div>
        </section>
    }
}